/* eslint-disable complexity */
import { AxiosError } from 'axios';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { demandGenPost } from '../../utils/clientRequests';
import {
  getRecaptchaToken,
  validateEmailPattern,
  validateRequired,
  validateTextPattern,
} from '../../utils/formHelpers';
import Alert from '../alert/Alert';
import RichText from '../rich_text/RichText';
import RecaptchaDisclaimer from './components/RecaptchaDisclaimer';

interface PetType {
  petType: {
    keyName: 'CAT' | 'DOG';
  };
  name: string;
  birthDate: string;
  firstItem: boolean;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  pets: string;
  petDetail: PetType[];
  agreed: boolean;
  language: {
    keyName: Languages;
  };
}

interface DemandGenFormProps {
  getFieldDataByName: Function;
  submitCallback?: Function;
  modal?: Boolean;
}

const DemandGenForm = ({ getFieldDataByName, submitCallback, modal }: DemandGenFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      pets: '',
      petDetail: [],
      agreed: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'petDetail',
  });
  const watchPets = watch('petDetail');
  const controlledPets = fields.map((field, index) => {
    return {
      ...field,
      ...watchPets[index],
    };
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState('');
  const apiRef = useRef<HTMLDivElement>(null);
  const [selectedPetType, setSelectedPetType] = useState<string | null>(null);
  const petTypes = ['DOG', 'CAT'];

  const handleAddMultiplePets = () => {
    const dogObject: PetType = {
      petType: {
        keyName: 'DOG',
      },
      name: '',
      birthDate: new Date().toISOString(),
      firstItem: true,
    };

    const catObject: PetType = {
      petType: {
        keyName: 'CAT',
      },
      name: '',
      birthDate: new Date().toISOString(),
      firstItem: true,
    };

    append([{ ...dogObject }, { ...catObject }]);
  };

  const handleAddPet = (type: 'CAT' | 'DOG', firstItem?: boolean) => {
    const newPet: PetType = {
      petType: {
        keyName: type,
      },
      name: '',
      birthDate: new Date().toISOString(),
      firstItem: !!firstItem,
    };

    append(newPet);
  };

  const handleRemovePet = (index: number) => {
    remove(index);
  };

  const handleClearPetType = (type: 'CAT' | 'DOG') => {
    controlledPets.forEach((pet: PetType, index) => {
      if (pet.petType.keyName === type) {
        handleRemovePet(index);
      }
    });
  };

  useEffect(() => {
    const existingDog = controlledPets.some(s => s.petType.keyName.toLowerCase() === 'dog');
    const existingCat = controlledPets.some(s => s.petType.keyName.toLowerCase() === 'cat');

    switch (selectedPetType) {
      case 'DOG': {
        if (!existingDog) {
          handleAddPet('DOG', true);
        }
        handleClearPetType('CAT');
        break;
      }
      case 'CAT': {
        if (!existingCat) {
          handleAddPet('CAT', true);
        }
        handleClearPetType('DOG');
        break;
      }
      case 'BOTH': {
        if (!existingCat && !existingDog) {
          handleAddMultiplePets();
        } else if (!existingCat) {
          handleAddPet('CAT', true);
        } else if (!existingDog) {
          handleAddPet('DOG', true);
        }
        break;
      }
      default:
        break;
    }
  }, [selectedPetType]);

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    const formattedData = {
      webform_id: 'demand_gen_form',
      agree_box: formData.agreed ? '1' : '0',
      first_name: formData.firstName,
      last_name: formData.lastName,
      email_address: formData.email,
      pet_detail: formData.petDetail.map((pet: PetType) => {
        return {
          pet_birthday: pet.birthDate,
          pet_name: pet.name,
          pet_type: pet.petType.keyName,
        };
      }),
      RecaptchaToken: '',
    };

    setSubmitting(true);
    formattedData.RecaptchaToken = await getRecaptchaToken();
    const demandGenResponse = await demandGenPost({ data: formattedData });
    if (!demandGenResponse) {
      setApiError('Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (demandGenResponse instanceof AxiosError) {
      setApiError(demandGenResponse.response?.data?.message || 'Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    const submitAnalyticsForm = {
      event: 'sign_up',
      eventCategory: 'demand form',
      eventAction: 'successful submission',
      eventLabel: 'page',
      eventParams: {
        form_type: 'demand form',
        site_id: 'purina.ca',
        form_id: modal ? 'demand modal' : 'demand page',
        form_name: 'demandGen',
      },
    };
    sendIt(submitAnalyticsForm);

    if (submitCallback) {
      submitCallback();
    }

    setSubmitted(true);
    reset();
    setSubmitting(false);
  };

  if (submitting) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{`${t('Submitting')}...`}</span>
          </Spinner>
          <span className="ms-3">{`${t('Please wait')}...`}</span>
        </Col>
      </Row>
    );
  }
  if (submitted) {
    return (
      <Row>
        <Col className="text-center">
          <Alert variant="success" className="my-3">
            <RichText body={getFieldDataByName('success_message')?.text} />
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
      <Row className="gx-3 gy-3">
        {apiError && (
          <Alert variant="error" role="alert" tabIndex={0} ref={apiRef}>
            {apiError}
          </Alert>
        )}
        <Col xs={12}>
          <Form.Group controlId="email" className="required">
            <Form.Label>{getFieldDataByName('email_address').title}</Form.Label>
            <Form.Control
              {...register('email', {
                required: validateRequired(getFieldDataByName('email_address').title),
                pattern: validateEmailPattern(),
              })}
              type="email"
              isInvalid={!!errors?.email}
              aria-invalid={!!errors?.email}
              placeholder="example@gmail.com"
              maxLength={128}
              aria-required="true"
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && errors.email.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId="firstName" className="required">
            <Form.Label>{getFieldDataByName('first_name').title}</Form.Label>
            <Form.Control
              {...register('firstName', {
                required: validateRequired(getFieldDataByName('first_name').title),
                pattern: validateTextPattern(getFieldDataByName('first_name').title),
              })}
              type="text"
              isInvalid={!!errors?.firstName}
              aria-invalid={!!errors?.firstName}
              placeholder={getFieldDataByName('first_name').title}
              maxLength={60}
              aria-required="true"
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName && errors.firstName.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId="lastName" className="required">
            <Form.Label>{getFieldDataByName('last_name').title}</Form.Label>
            <Form.Control
              {...register('lastName', {
                required: validateRequired(getFieldDataByName('last_name').title),
                pattern: validateTextPattern(getFieldDataByName('last_name').title),
              })}
              type="text"
              isInvalid={!!errors?.lastName}
              aria-invalid={!!errors?.lastName}
              placeholder={getFieldDataByName('last_name').title}
              maxLength={60}
              aria-required="true"
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName && errors.lastName.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group controlId="petDetail">
            <Form.Label>{getFieldDataByName('pets').title}</Form.Label>
            <Row className="gy-3 gx-2 mb-4">
              <Col xs={4} md="auto">
                <Button
                  size="sm"
                  variant={selectedPetType === 'DOG' ? 'dark' : 'outline-dark'}
                  onClick={() => setSelectedPetType('DOG')}
                  className="w-100 d-block"
                >
                  {getFieldDataByName('pets')?.options[0].label}
                </Button>
              </Col>
              <Col xs={4} md="auto">
                <Button
                  size="sm"
                  variant={selectedPetType === 'CAT' ? 'dark' : 'outline-dark'}
                  onClick={() => setSelectedPetType('CAT')}
                  className="w-100 d-block"
                >
                  {getFieldDataByName('pets').options[1].label}
                </Button>
              </Col>
              <Col xs={4} md="auto">
                <Button
                  size="sm"
                  variant={selectedPetType === 'BOTH' ? 'dark' : 'outline-dark'}
                  onClick={() => setSelectedPetType('BOTH')}
                  className="w-100 d-block"
                >
                  {getFieldDataByName('pets').options[2].label}
                </Button>
              </Col>
            </Row>
            {petTypes
              .filter(p => (selectedPetType === 'BOTH' ? true : p === selectedPetType))
              .map(pType => {
                const addButtonLabel =
                  pType.toLowerCase() === 'cat' ? t('Cat Add Label') : t('Dog Add Label');
                return (
                  <>
                    {controlledPets
                      .filter(pet => pet.petType.keyName.toLowerCase() === pType.toLowerCase())
                      .map(pet => {
                        const petIndex = controlledPets.findIndex(p => p === pet);
                        const petsError = errors?.pets && errors.pets[petIndex];
                        const petType: string = pet.petType.keyName.toLowerCase();
                        const nameLabel =
                          petType === 'cat' ? t('Cat Name Label') : t('Dog Name Label');
                        const birthdayLabel =
                          petType === 'cat' ? t('Cat Birthday Label') : t('Dog Birthday Label');
                        const removeLabel =
                          petType === 'cat' ? t('Cat Remove Label') : t('Dog Remove Label');

                        return (
                          <>
                            {!pet.firstItem && (
                              <Row>
                                <Col
                                  xs={12}
                                  className="d-flex align-items-center justify-content-end"
                                >
                                  <Button
                                    variant="link"
                                    style={{
                                      padding: '0',
                                    }}
                                    onClick={() => handleRemovePet(petIndex)}
                                  >
                                    {removeLabel}
                                  </Button>
                                </Col>
                              </Row>
                            )}
                            <Row key={petIndex} className="gy-3 gx-2 py-2">
                              <Col xs={12} md={6}>
                                <input
                                  type="hidden"
                                  value={pet.petType.keyName}
                                  {...register(`petDetail.${petIndex}.petType.keyName` as const)}
                                />
                                <Form.Group controlId={`petDetail-${petIndex}-name`}>
                                  <Form.Label>{nameLabel}</Form.Label>
                                  <Form.Control
                                    {...register(`petDetail.${petIndex}.name` as const, {
                                      pattern: validateTextPattern('Pet name'),
                                    })}
                                    type="text"
                                    isInvalid={petsError && !!petsError?.name}
                                    aria-invalid={petsError && !!petsError?.name}
                                    placeholder={t('Name')}
                                    maxLength={255}
                                    aria-required="true"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {petsError && petsError?.name && petsError?.name.message}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Group controlId={`petDetail-${petIndex}-birthDate`}>
                                  <Form.Label>{birthdayLabel}</Form.Label>
                                  <Form.Control
                                    {...register(`petDetail.${petIndex}.birthDate` as const, {
                                      min: {
                                        value: '1753-01-01',
                                        message: 'Select a later date.',
                                      },
                                      max: {
                                        value: '9999-12-31',
                                        message: 'Select an earlier date.',
                                      },
                                    })}
                                    type="date"
                                    isInvalid={
                                      errors?.petDetail &&
                                      errors?.petDetail[petIndex] &&
                                      !!errors.petDetail[petIndex]
                                    }
                                    aria-invalid={
                                      errors?.petDetail &&
                                      errors?.petDetail[petIndex] &&
                                      !!errors.petDetail[petIndex]
                                    }
                                    placeholder={t('Date')}
                                    aria-required="true"
                                    min="1753-01-01"
                                    max="9999-12-31"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors?.petDetail &&
                                      errors?.petDetail[petIndex]?.birthDate &&
                                      errors?.petDetail[petIndex]?.birthDate?.message}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>

                            <hr />
                          </>
                        );
                      })}
                    <Row className="gy-3 gx-2 py-2">
                      <Col xs={12} className="d-flex justify-content-end">
                        <Button
                          variant="link"
                          style={{
                            padding: '0',
                          }}
                          onClick={() => handleAddPet(pType.toUpperCase() as 'CAT' | 'DOG')}
                        >
                          {addButtonLabel}
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              })}
          </Form.Group>
        </Col>

        <Col xs={12} className="mt-3">
          {getFieldDataByName('disclaimer').text && (
            <RichText body={getFieldDataByName('disclaimer').text} />
          )}
          <RecaptchaDisclaimer />
        </Col>
        <Col xs={12}>
          <Form.Group controlId="agreed" className="required">
            <Form.Check typography-h2 type="checkbox" label={getFieldDataByName('agree_box').title}>
              <Form.Check.Input
                type="checkbox"
                aria-required="true"
                {...register('agreed', {
                  required: 'Please check this box if you want to proceed',
                })}
              />
              <Form.Check.Label>{getFieldDataByName('agree_box').title}</Form.Check.Label>
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.agreed && errors.agreed.message}
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Button
            className="justify-content-center mt-3"
            type="submit"
            size="sm"
            style={{ padding: '.5rem 3rem' }}
            disabled={submitting}
          >
            {submitting ? 'Please wait...' : getFieldDataByName('actions').submitLabel}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DemandGenForm;
